import './App.css';
import './scss/custom.scss';
import Main from "./app/main";
// import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    Main()
  );
}

export default App;
